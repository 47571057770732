// @flow
import * as React from 'react';
import { Layout } from 'antd';

import ErrorBoundary from '../ErrorBoundary';
import PageHeader from './PageHeader';
import './AuthedPage.scss';

const { Content, Footer } = Layout;

type Props = {
  children: React.Node,
};

const currentYear = (new Date()).getFullYear();
const copyrightYear = currentYear === 2018 ? '2018' : `2018 - ${currentYear}`;

const AuthedPage = ({ children }: Props) => (
  <Layout>
    <Layout className="layout-right">
      <ErrorBoundary>
        <PageHeader />
      </ErrorBoundary>
      <Content className="app-content">
        <div className="app-content__inner">
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </div>
      </Content>
      <Footer className="app-footer">
        {`© ${copyrightYear} Plum Village.`}
      </Footer>
    </Layout>
  </Layout>
);

export default AuthedPage;
