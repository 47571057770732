// @flow
import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { message, Button } from 'antd';

import './Login.scss';

const provider = new firebase.auth.GoogleAuthProvider();

type Props = {};

type State = {
  isLoading: boolean,
};

class Login extends React.Component<Props, State> {
  state = { isLoading: false }

  handleClick = () => {
    this.setState({ isLoading: true });
    firebase.auth().signInWithPopup(provider)
      .catch((error) => {
        this.setState({ isLoading: false });
        // eslint-disable-next-line no-console
        console.error(`${error.code}\n${error.message}`);
        message.error('Oops! something went wrong. Please try again.');
      });
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div className="login">
        <h1>Ledger Manager</h1>
        <h2>Log in</h2>
        <Button
          type="primary"
          size="large"
          block
          icon="google"
          loading={isLoading}
          onClick={this.handleClick}
        >
          Log in with Google
        </Button>
      </div>
    );
  }
}

export default Login;
