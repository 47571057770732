// @flow
import React from 'react';
import firebase from 'firebase/app';
import styled from 'styled-components';
import moment from 'moment';
import {
  Spin, Row, Col, Form, Button, Radio, Input, Select, InputNumber, DatePicker,
  Divider, Modal, message,
} from 'antd';

import type { Entry, Person, AutoCompleteOption } from '../../../types';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TextArea } = Input;

const FooterAction = styled.div`
  text-align: right;
`;

type Props = {}

type State = {
  entry: Entry,
  personOptions: Array<AutoCompleteOption>,
  isLoading: boolean,
  isSaving: boolean,
  modalVisible: boolean,
  newPerson: Person,
  isSavingPerson: boolean,
}

class NewEntry extends React.Component<Props, State> {
  defaultEntry: Entry = {
    person: '',
    particular: '',
    amount: 0,
    type: 'giving',
    date: Date.now(),
    comment: '',
  }

  defaultPerson: Person = {
    name: '',
  }

  state = {
    entry: this.defaultEntry,
    personOptions: [],
    isLoading: true,
    isSaving: false,
    modalVisible: false,
    newPerson: this.defaultPerson,
    isSavingPerson: false,
  };

  componentDidMount() {
    this.loadPersonOptions();
  }

  loadPersonOptions = () => {
    const firestore = firebase.firestore();

    // fetch person options
    firestore.collection('persons')
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.metadata.fromCache) {
          throw new Error('No Internet!');
        }

        const personOptions = [];
        querySnapshot.forEach((person) => {
          const { name } = person.data();
          personOptions.push({ value: person.id, text: name });
        });

        this.setState({ personOptions, isLoading: false });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        message.error('Oops! something went wrong while loading person options');
      });
  }

  reloadPersonOptions = () => {
    this.setState({ isLoading: true }, this.loadPersonOptions);
  }

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  }

  handlePersonChange = (key: string, value: any) => {
    this.setState(({ newPerson }) => ({ newPerson: { ...newPerson, [key]: value } }));
  }

  handleModalOk = () => {
    const { newPerson } = this.state;

    this.setState({ isSavingPerson: true });

    const firestore = firebase.firestore();

    const data = {
      ...newPerson,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    firestore.collection('persons').doc()
      .set(data)
      .then(() => {
        this.reloadPersonOptions();
        message.success('Successfully saved person');
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        message.error('Oops! something went wrong while saving the person');
      })
      .finally(() => {
        this.setState({
          newPerson: this.defaultPerson,
          isSavingPerson: false,
          modalVisible: false,
        });
      });
  }

  handleModalCancel = () => {
    this.setState({
      modalVisible: false,
    });
  }

  handleChange = (key: string, value: any) => {
    this.setState(({ entry }) => ({ entry: { ...entry, [key]: value } }));
  }

  handleEntrySave = () => {
    const { entry } = this.state;

    if (!entry.person || !entry.person.trim()) {
      message.error('Person is required');
      return;
    }

    if (!entry.particular || !entry.particular.trim()) {
      message.error('Particular is required');
      return;
    }

    if (!entry.amount || entry.amount === 0) {
      message.error('Amount is required and must be greater than 0');
      return;
    }

    if (!entry.type || !['giving', 'receiving'].includes(entry.type)) {
      message.error('Amount is required and must be greater than 0');
      return;
    }

    this.setState({ isSaving: true });

    firebase.firestore().collection('entries').doc()
      .set(entry)
      .then(() => {
        message.success('Successfully saved entry');
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        message.error('Oops! something went wrong while saving the entry');
      })
      .finally(() => {
        this.setState({
          entry: this.defaultEntry,
          isSaving: false,
        });
      });
  }

  render() {
    const {
      entry, isSaving, isLoading, personOptions, modalVisible, newPerson, isSavingPerson,
    } = this.state;
    return (
      <>
        <Spin spinning={isLoading} delay={200}>
          <FormItem label="Person">
            <Select
              value={entry.person || null}
              style={{ width: '260px' }}
              placeholder="Select a person"
              onChange={value => this.handleChange('person', value)}
              disabled={isSaving}
            >
              {personOptions.map(({ value, text }) => (
                <Option key={value} value={value}>{text}</Option>
              ))}
            </Select>
            <span> or </span>
            <Button type="primary" onClick={this.showModal}>create new one</Button>
          </FormItem>
          <Row gutter={{ xs: 0, md: 24, lg: 32 }}>
            <Col xs={24} md={12}>
              <FormItem label="Particular">
                <Input
                  value={entry.particular || ''}
                  onChange={event => this.handleChange('particular', event.target.value)}
                  disabled={isSaving}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={12}>
              <FormItem label="Amount">
                <InputNumber
                  value={entry.amount || 0.00}
                  onChange={value => this.handleChange('amount', value)}
                  disabled={isSaving}
                />
              </FormItem>
            </Col>
          </Row>
          <FormItem>
            <Radio.Group
              value={entry.type}
              onChange={event => this.handleChange('type', event.target.value)}
            >
              <Radio.Button value="giving">Giving</Radio.Button>
              <Radio.Button value="receiving">Receiving</Radio.Button>
            </Radio.Group>
          </FormItem>
          <FormItem label="Date">
            <DatePicker
              showTime
              showToday
              defaultValue={moment(entry.date, 'x')}
              onChange={date => this.handleChange('date', date ? date.format('x') : Date.now())}
              disabled={isSaving}
            />
          </FormItem>
          <FormItem label="Comment">
            <TextArea
              value={entry.comment || ''}
              onChange={event => this.handleChange('comment', event.target.value)}
              disabled={isSaving}
            />
          </FormItem>

          <Divider />

          <FooterAction>
            <Button
              type="primary"
              size="large"
              onClick={this.handleEntrySave}
              loading={isSaving}
              disabled={isLoading}
            >
              Save
            </Button>
          </FooterAction>
        </Spin>

        <Modal
          title="Add new person"
          visible={modalVisible}
          okText="Save"
          confirmLoading={isSavingPerson}
          cancelButtonProps={{ disabled: isSavingPerson }}
          onOk={this.handleModalOk}
          onCancel={this.handleModalCancel}
        >
          <FormItem label="Name">
            <Input
              value={newPerson.name || ''}
              onChange={event => this.handlePersonChange('name', event.target.value)}
              disabled={isSavingPerson}
            />
          </FormItem>
        </Modal>
      </>
    );
  }
}

export default NewEntry;
