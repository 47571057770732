// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

// Configure firebase
import './config/firebase';

const root = document.getElementById('root');

if (root) {
  // eslint-disable-next-line react/jsx-filename-extension
  ReactDOM.render(<App />, root);
  registerServiceWorker();
}
