// @flow
import React from 'react';
import firebase from 'firebase/app';
import moment from 'moment';
import {
  Spin, Form, Button, Select, Table, Tooltip, Tag, message,
} from 'antd';

import type { Entry, AutoCompleteOption } from '../../../types';

const { Item: FormItem } = Form;
const { Option } = Select;

type Props = {}

type State = {
  person: ?string,
  entries: Entry[],
  personOptions: AutoCompleteOption[],
  isLoading: boolean,
}

class Ledger extends React.Component<Props, State> {
  state = {
    person: null,
    entries: [],
    personOptions: [],
    isLoading: true,
  };

  componentDidMount() {
    const firestore = firebase.firestore();

    // fetch person options
    firestore.collection('persons')
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.metadata.fromCache) {
          throw new Error('No Internet!');
        }

        const personOptions = [];
        querySnapshot.forEach((person) => {
          const { name } = person.data();
          personOptions.push({ value: person.id, text: name });
        });

        this.setState({ personOptions, isLoading: false });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        message.error('Oops! something went wrong while loading person options');
      });
  }

  handlePersonChange = (person: string) => {
    this.setState({ person });
  }

  loadEntries = () => {
    const { person } = this.state;

    if (!person) {
      return;
    }

    this.setState({ isLoading: true });

    firebase.firestore().collection('entries')
      .where('person', '==', person)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.metadata.fromCache) {
          throw new Error('No Internet!');
        }

        let entries = [];
        querySnapshot.forEach((entry) => {
          // some old entries has wrong spelling of the prop particular, let's fix it
          const { perticular, ...rest } = entry.data();
          entries.push({ id: entry.id, particular: perticular, ...rest });
        });

        entries = entries.sort((a, b) => b.date - a.date);

        this.setState({ entries, isLoading: false });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        message.error('Oops! something went wrong while loading entries');
      });
  }

  render() {
    const {
      person, entries, personOptions, isLoading,
    } = this.state;

    const columns = [{
      title: 'Particular',
      dataIndex: 'particular',
      key: 'particular',
    }, {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number, entry: Entry) => (
        <Tag color={entry.type === 'giving' ? 'red' : 'green'}>
          {entry.type === 'giving' ? '-' : '+'}
          {`₹${amount}`}
        </Tag>
      ),
    }, {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: number => moment(number, 'x').format('lll'),
    }, {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      render: comment => (
        <Tooltip title={comment}>comment</Tooltip>
      ),
    }];

    const total = entries.reduce((sum, entry) => {
      if (entry.type === 'giving') {
        return sum - entry.amount;
      }
      return sum + entry.amount;
    }, 0);

    return (
      <Spin spinning={isLoading} delay={200}>
        <FormItem label="Person">
          <Select
            value={person || null}
            style={{ width: '260px' }}
            placeholder="Select a person"
            onChange={this.handlePersonChange}
          >
            {personOptions.map(({ value, text }) => (
              <Option key={value} value={value}>{text}</Option>
            ))}
          </Select>
          {' '}
          <Button type="primary" onClick={this.loadEntries}>Load entries</Button>
        </FormItem>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={entries}
          footer={() => `Total: ${total}`}
          locale={{ emptyText: 'No entries' }}
        />
      </Spin>
    );
  }
}

export default Ledger;
