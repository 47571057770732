// @flow
import * as React from 'react';
import firebase from 'firebase/app';
import { Route, Redirect } from 'react-router-dom';

type Props = {
  component: React.ComponentType<any>,
};

const AuthedRoute = ({ component: Component, ...rest }: Props) => (
  <Route
    {...rest}
    render={() => (firebase.auth().currentUser
      ? <Component />
      : <Redirect to="/login" />
    )}
  />
);

export default AuthedRoute;
