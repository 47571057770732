// @flow
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';

import AuthedRoute from './components/AuthedRoute';
import UnauthedRoute from './components/UnauthedRoute';
import MainScreen from './components/MainScreen';
import Login from './components/Login';
import NotFound from './components/NotFound';

const Routes = () => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Router>
    <Switch>
      <AuthedRoute path="/" exact component={MainScreen} />
      <UnauthedRoute path="/login" component={Login} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;
