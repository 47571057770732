// @flow
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.Node,
}

type State = {
  hasError: boolean,
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    // eslint-disable-next-line no-console
    console.log(error, info);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <Wrapper>Hmm... something went wrong.</Wrapper>;
    }
    return children;
  }
}

const Wrapper = styled.div`
  padding: 3px 6px;
  color: #dc3545;
  font-weight: bold;
`;

export default ErrorBoundary;
