// @flow
import React from 'react';
import firebase from 'firebase/app';
import { message } from 'antd';

import Routes from '../Routes';
import './App.scss';

type Props = any;

type State = {
  currentUser?: Object,
};

class App extends React.PureComponent<Props, State> {
  state = { currentUser: undefined }

  // eslint-disable-next-line react/sort-comp
  hideMessage: () => void;

  componentDidMount() {
    firebase.auth().onAuthStateChanged((currentUser) => {
      this.setState({ currentUser });
    });
    this.hideMessage = message.loading('Loading...', 0);
  }

  componentWillUnmount() {
    this.hideMessage();
  }

  render() {
    const { currentUser } = this.state;

    // Wait for firebase.auth() object to finish initializing.
    if (currentUser === undefined) {
      return null;
    }

    // We can now safely use auth.currentUser in the app
    // which otherwise might also be null because the auth object has not finished initializing.
    this.hideMessage();
    return <Routes />;
  }
}

export default App;
