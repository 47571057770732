// @flow
import React from 'react';
import firebase from 'firebase/app';
import { Layout, Avatar, Button } from 'antd';
import { withRouter } from 'react-router-dom';

const { Header } = Layout;

const PageHeader = () => {
  const { currentUser } = firebase.auth();

  return (
    <Header className="app-header">
      <h1 className="app-header__title">Ledger Manager</h1>
      <div className="app-header__right">
        <Avatar className="app-header__avatar" src={currentUser.photoURL} />
        <Button type="danger" ghost onClick={() => firebase.auth().signOut()}>Sign out</Button>
      </div>
    </Header>
  );
};

export default withRouter(PageHeader);
