// @flow
import React from 'react';
import { Tabs } from 'antd';

import AuthedPage from '../AuthedPage';
import ErrorBoundary from '../ErrorBoundary';
import NewEntry from './components/NewEntry';
import Ledger from './components/Ledger';

const { TabPane } = Tabs;

const MainScreen = () => (
  <AuthedPage fullwidth>
    <Tabs defaultActiveKey="new-entry">
      <TabPane tab="New entry" key="new-entry">
        <ErrorBoundary>
          <NewEntry />
        </ErrorBoundary>
      </TabPane>
      <TabPane tab="Ledger" key="ledger">
        <ErrorBoundary>
          <Ledger />
        </ErrorBoundary>
      </TabPane>
    </Tabs>
  </AuthedPage>
);

export default MainScreen;
