import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

// Firebase config
const firebaseConfig = {
  apiKey: 'AIzaSyBRxZG2rJxVCUgIRHv1oES8ejUm9JD-ftw',
  authDomain: 'my-ledger-manager.firebaseapp.com',
  databaseURL: 'https://my-ledger-manager.firebaseio.com',
  projectId: 'my-ledger-manager',
  storageBucket: '',
  messagingSenderId: '814167134623',
};
firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const settings = { timestampsInSnapshots: true };
firestore.settings(settings);
